<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="
                  () => {
                    $router.push('/admin/product-management/product/add');
                  }
                "
              />
              <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              />
            </div>
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="menu"
          dataKey="_id"
          v-model:selection="selectedProducts"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Products</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Search..." />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="name" header="Menu Name" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column field="weight" header="Weight" :sortable="true">
            <template #body="slotProps"> {{ slotProps.data.weight }}Kg </template>
          </Column>
          <Column field="pre_order_day" header="Pre-order Days" :sortable="true">
            <template #body="slotProps">
              {{
                slotProps.data.pre_order_day > 0
                  ? `${slotProps.data.pre_order_day} ${slotProps.data.pre_order_day > 1 ? "Days" : "Day"}`
                  : "No Pre-order"
              }}
            </template>
          </Column>
          <Column field="is_active" header="Active Status" :sortable="true">
            <template #body="slotProps">
              {{ `${slotProps.data.is_active}` ? "Active" : "Not Active" }}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;" header="Actions">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning mr-2"
                @click="
                  () => {
                    $router.push(`/admin/product-management/product/update/${slotProps.data._id}`);
                  }
                "
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger mt-2"
                @click="confirmDeleteItem(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <!-- create -->
        <Dialog v-model:visible="showModalCreate" :style="{ width: '450px' }" header="Add Product" :modal="true" class="p-fluid">
          <div class="field center-items-vertical">
            <label style="margin: 0px" for="active">Pre-order :</label>
            <InputSwitch v-model="form.is_pre_order" />
          </div>
          <div v-if="form.is_pre_order" class="field">
            <label for="icon">Pre Order Day :</label>
            <InputNumber v-model="form.pre_order_day" showButtons mode="decimal"></InputNumber>
          </div>
          <div class="field">
            <label for="name">Product Name :</label>
            <InputText id="name" v-model.trim="form.name" required="true" autofocus />
          </div>

          <div class="field">
            <label for="route">Weight(Kg) :</label>
            <InputNumber v-model="form.weight" showButtons mode="decimal"></InputNumber>
          </div>
          <div class="field">
            <label for="route">Description :</label>
            <Textarea v-model="form.description" id="address" rows="4" />
          </div>

          <div class="field center-items-vertical">
            <label style="margin: 0px" for="active">Big Stuff :</label>
            <InputSwitch v-model="form.is_big_stuff" />
            <label style="margin: 0px" for="active">Active:</label>
            <InputSwitch v-model="form.is_active" />
          </div>
          <div class="field center-items-vertical"></div>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="showModalCreate = false" />
            <Button
              :label="isLoading ? 'Creating' : 'Create'"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="createItem"
            />
          </template>
        </Dialog>

        <!-- Edit Modal Item -->
        <Dialog v-model:visible="showModalEdit" :style="{ width: '450px' }" header="Edit Menu" :modal="true" class="p-fluid">
          <div class="field center-items-vertical">
            <label style="margin: 0px" for="active">Pre-order :</label>
            <InputSwitch v-model="form.is_pre_order" />
          </div>
          <div v-if="form.is_pre_order" class="field">
            <label for="icon">Pre Order Day :</label>
            <InputNumber v-model="form.pre_order_day" showButtons mode="decimal"></InputNumber>
          </div>
          <div class="field">
            <label for="name">Product Name :</label>
            <InputText id="name" v-model.trim="form.name" required="true" autofocus />
          </div>

          <div class="field">
            <label for="route">Weight(Kg) :</label>
            <InputNumber v-model="form.weight" showButtons mode="decimal"></InputNumber>
          </div>
          <div class="field">
            <label for="route">Description :</label>
            <Textarea v-model="form.description" id="address" rows="4" />
          </div>

          <div class="field center-items-vertical">
            <label style="margin: 0px" for="active">Big Stuff :</label>
            <InputSwitch v-model="form.is_big_stuff" />
            <label style="margin: 0px" for="active">Active:</label>
            <InputSwitch v-model="form.is_active" />
          </div>
          <div class="field center-items-vertical"></div>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="showModalCreate = false" />
            <Button
              :label="isLoading ? 'Creating' : 'Create'"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="editItem"
            />
          </template>
        </Dialog>

        <!-- Delete Modal Item -->
        <Dialog v-model:visible="showModalDeleteItem" :style="{ width: '450px' }" header="Delete Menu Confirm ?" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span
              >Are you sure you want to delete <b>{{ temp_item.name }}</b> ?</span
            >
          </div>
          <template #footer>
            <Button @click="showModalDeleteItem = !showModalDeleteItem" label="No" icon="pi pi-times" class="p-button-text" />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="deleteItem(temp_item._id)"
            />
          </template>
        </Dialog>

        <!-- Delete Modal Multiple -->
        <Dialog v-model:visible="showModalDelete" :style="{ width: '450px' }" header="Delete Products Confirm ?" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Are you sure you want to delete the selected Products?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="showModalDelete = false" />
            <Button label="Yes" icon="pi pi-check" class="p-button-text" :loading="isLoading" @click="deleteMultipleUser" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
// import MenuApi from "../../api/Menu";
import ProductApi from "../../api/Product";
import moment from "moment";

export default {
  data() {
    return {
      moment: moment,
      showModalCreate: false,
      showModalDelete: false,
      showModalDeleteItem: false,
      showModalEdit: false,
      selectedRole: {},
      deleteProductDialog: false,
      menu: [],
      temp_item: {},
      selectedProducts: [],
      isLoading: false,
      form: {
        name: "",
        weight: 0,
        pre_order_day: 0,
        description: "",
        is_pre_order: false,
        is_use_variant: false,
        is_active: false,
        is_big_stuff: true,
        categories: [],
        collections: [],
      },
    };
  },
  productService: null,
  created() {
    this.initFilters();
  },
  mounted() {
    this.getProducts();
  },

  methods: {
    async getProducts() {
      try {
        const response = await ProductApi.Get();
        if (response.data.status === 200) {
          this.menu = response.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async createItem() {
      this.isLoading = true;
      try {
        const response = await ProductApi.Add({
          ...this.form,
          collections: [],
          categories: [],
          is_use_variant: false,
        });

        if (response.data.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "New Menu Created!",
            life: 3000,
          });

          // Reset Form
          this.form = {};

          this.getProducts();
          this.isLoading = false;
          this.showModalCreate = false;
        } else {
          this.form = {};

          this.$toast.add({
            severity: "error",
            summary: "Failed!",
            detail: response.data.message,
            life: 3000,
          });
          this.isLoading = false;
          this.showModalCreate = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async showEditModal(user) {
      try {
        this.form = user;
        this.showModalEdit = true;
      } catch (error) {
        console.log(error);
      }
    },

    async editItem() {
      try {
        this.isLoading = true;
        const response = await ProductApi.Update(this.form._id, this.form);

        if (response.data.status === 200) {
          this.getProducts();
          this.form = {};
          this.isLoading = false;
          this.showModalEdit = false;

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Menu Updated!",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Update Failed!",
            detail: "Something went wrong",
            life: 3000,
          });
          this.showModalEdit = false;
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async deleteMultipleUser() {
      this.isLoading = true;
      try {
        //   reset selected users
        let payload = {
          id: [],
        };

        for (let i = 0; i < this.selectedProducts.length; i++) {
          payload.id.push(this.selectedProducts[i]._id);
        }

        const response = await ProductApi.DeleteMultiple(payload);
        if (response.data.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: response.data.message,
            life: 3000,
          });
          this.getProducts();
          this.isLoading = false;
          this.selectedUsers = [];
          this.showModalDelete = false;
        }
        this.selectedProducts = [];
      } catch (error) {
        console.log(error);
      }
    },

    confirmDeleteItem(user) {
      this.showModalDeleteItem = true;
      this.temp_item = user;
    },

    async deleteItem(id) {
      try {
        this.isLoading = true;
        const response = await ProductApi.Delete(id);

        if (response.data.status === 200) {
          this.isLoading = false;
          this.showModalDeleteItem = false;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: `${this.temp_item.username} Deleted!`,
            life: 3000,
          });
          this.getProducts();
          this.temp_item = {};
        }
      } catch (error) {
        console.log(error);
      }
    },

    confirmDeleteSelected() {
      this.showModalDelete = true;
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";

.center-items-vertical {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
